<template>
    <div :is="layout">
        <v-card>

            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn @click="$refs.content.src = $refs.content.src == 'https://cauc-atc-1255710621.cos.ap-nanjing.myqcloud.com/html/3d_volume_slice.html' ? 'https://cauc-atc-1255710621.cos.ap-nanjing.myqcloud.com/html/3d_volume.html' : 'https://cauc-atc-1255710621.cos.ap-nanjing.myqcloud.com/html/3d_volume_slice.html'">切换模式</v-btn>
            </v-card-actions>
        </v-card>
        <iframe ref="content" src="https://cauc-atc-1255710621.cos.ap-nanjing.myqcloud.com/html/3d_volume.html" scrolling="no" style="height: calc(100vh - 150px)" width="100%" frameborder="0"></iframe>
    </div>
</template>
<script>
    export default {
        name: 'electromagnetic',
        data: () => ({
            layout: null,
        }),
        mounted() {
            this.layout = this.$route.meta.use_layout;
        }
    }
</script>
